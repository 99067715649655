// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

// vendor

import jQuery from "jquery";
import Swiper, { A11y, Autoplay, Grid, Navigation } from "swiper";
import "bootstrap";

// custom

jQuery(document).ready(function () {
    let swiper = new Swiper(".Banner-slider", {
        modules: [A11y, Autoplay, Navigation],
        slidesPerView: 1,
        spaceBetween: 50,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
            pauseOnMouseEnter: true,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        }
    });

    if (jQuery(window).width() < 991) {
        swiper = new Swiper(".insta-Slider", {
            modules: [A11y, Autoplay, Grid, Navigation],
            slidesPerView: 2,
            grid: {
                rows: 2,
            },
            spaceBetween: 20,
            loop: true,
            loopFillGroupWithBlank: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                300: {
                    slidesPerView: 2
                },
                767: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 4
                },
                1366: {
                    slidesPerView: 6
                }
            }
        });
    } else {
        swiper = new Swiper(".insta-Slider", {
            modules: [A11y, Autoplay, Navigation],
            slidesPerView: 6,
            spaceBetween: 20,
            loop: true,
            loopFillGroupWithBlank: true,
            autoplay: {
                delay: 2000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            navigation: {
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
            },
            breakpoints: {
                300: {
                    slidesPerView: 2
                },
                767: {
                    slidesPerView: 2
                },
                1024: {
                    slidesPerView: 4
                },
                1366: {
                    slidesPerView: 6
                }
            }
        });

    }

    jQuery(".swiper-container").on("mouseenter", function () {
        console.log("stop autoplay");
        swiper.stopAutoplay();
    });

    jQuery(".swiper-container").on("mouseleave", function () {
        console.log("start autoplay");
        swiper.startAutoplay();
    });

    jQuery(".menu-toggle").click(function () {
        jQuery("body").toggleClass("main-menu-overlay-active");
        jQuery("html").toggleClass("overflow-hidden");
    });
});

jQuery("button#find-it-btn").click(function () {
    // alert('here');
    const search_link = jQuery("#search_link").attr("data-link");
    const search_key = jQuery("#search_key").attr("data-key");
    const search_type = jQuery("#search_type").val();
    const search = jQuery("#search").val();
    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            const url = `${search_link}?${search_key}=${search}`;
            window.open(url, "_blank");
        } else {
            return true;
        }
    }
    return false;
});

jQuery("button#find-it-btn-mobile").click(function () {
    // alert('here');
    const search_link = jQuery("#search_link_mobile").attr("data-link");
    const search_key = jQuery("#search_key_mobile").attr("data-key");
    const search_type = jQuery("#search_type_mobile").val();
    const search = jQuery("#search_mobile").val();
    if (search_type != "" && search != "") {
        if (search_type == "catalog") {
            const url = `${search_link}?${search_key}=${search}`;
            window.open(url, "_blank");
        } else {
            return true;
        }
    }
    return false;
});
