/**
 * File navigation.js.
 *
 * Handles toggling the navigation menu for small screens and enables TAB key
 * navigation support for dropdown menus.
 */
( function () {
    const mobileNavigation = document.getElementById( "mobile-navigation" );
    const siteNavigation = document.getElementById( "site-navigation" );

    // Return early if the navigation don't exist.
    if ( ! siteNavigation ) {
        return;
    }

    const mobileButton = mobileNavigation.getElementsByTagName( "button" )[ 0 ];
    const button = siteNavigation.getElementsByTagName( "button" )[ 0 ];

    // Return early if the button don't exist.
    if ( "undefined" === typeof button ) {
        return;
    }

    const menu = mobileNavigation.getElementsByTagName( "ul" )[ 0 ];

    // Hide menu toggle button if menu is empty and return early.
    if ( "undefined" === typeof menu ) {
        button.style.display = "none";
        return;
    }

    if ( ! menu.classList.contains( "nav-menu" ) ) {
        menu.classList.add( "nav-menu" );
    }

    // Toggle the .toggled class and the aria-expanded value each time the button is clicked.
    button.addEventListener( "click", function () {
        mobileNavigation.classList.toggle( "toggled" );
        mobileButton.setAttribute( "aria-expanded", "true" );
        button.setAttribute( "aria-expanded", "false" );
    } );

    mobileButton.addEventListener( "click", function () {
        mobileNavigation.classList.toggle( "toggled" );
        mobileButton.setAttribute( "aria-expanded", "false" );
        button.setAttribute( "aria-expanded", "true" );
    } );

    // Remove the .toggled class and set aria-expanded to false when the user clicks outside the navigation.
    document.addEventListener( "click", function ( event ) {
        const isClickInside = mobileNavigation.contains( event.target );

        if ( ! isClickInside ) {
            mobileButton.setAttribute( "aria-expanded", "false" );
            button.setAttribute( "aria-expanded", "false" );
        }
    } );
}() );
